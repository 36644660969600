import { ScamReportCardProps } from '@/features';
import { ChainKind as ChainType } from '@/generated/graphql';
import { ScamCategory } from '@/types/scam-categories';
import { ReportSource } from '@/types/scam-report-source';
// @TODO: delete and stop using this file
// except for stories that need them

export const SHORT_DESCRIPTION =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.';
export const MEDIUM_DESCRIPTION =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.';
export const LONG_DESCRIPTION =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
export const SUPER_LONG_DESCRIPTION =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. .orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

export const DEMO_EVIDENCE = [
  {
    label: 'https://google.com',
    imgSrc: 'https://picsum.photos/id/1/1024/768',
  },
  {
    label: 'https://facebook.com',
    imgSrc: 'https://picsum.photos/id/2/1024/768',
  },
  {
    label:
      'https://docs.google.com/forms//1V1qdJUBKPPAukPHRum8RN3CL-ROcLyhD4O-OsRPmuZadfajsdlfjalsdkfjqoweuiroqwjlavcOO-asdf',
    imgSrc: 'https://picsum.photos/id/3/1024/768',
  },
];

export const DEMO_KNOWN_SCAMMER = {
  name: 'Scammy Scammer',
  contactChannels: [
    {
      type: 'phone',
      contact: '16391000123',
    },
    {
      type: 'email',
      contact: 'scammy@scammer.net',
    },
    {
      type: 'email',
      contact: 'scammy@scammer.net',
    },
  ],
};

export const DEMO_RESULTS: ScamReportCardProps[] = [
  {
    isPrivate: false,
    reportId: '1',
    category: ScamCategory.AIRDROP,
    description: LONG_DESCRIPTION,
    authorUsername: 'Joe McGill',
    reportDate: new Date('1/9/22'),
    netVoteCount: 3,
    source: ReportSource.CHAINABUSE,
    addresses: [
      {
        id: '1',
        address: 'AUrox7sHx1L8mxEPrNqkVjHa16CXQ73UbgZtZTNPNLjx',
        chain: ChainType.BTC,
        label: 'Kraken',
      },
    ],
  },
  {
    isPrivate: false,
    reportId: '2',
    category: ScamCategory.RUG_PULL,
    description: SHORT_DESCRIPTION,
    authorUsername: 'crytopunk',
    reportDate: new Date('1/1/22'),
    netVoteCount: 16,
    source: ReportSource.CHAINABUSE,
    addresses: [
      {
        id: '1',
        address: 'AUrox7sHx1L8mxEPrNqkVjHa16CXQ73UbgZtZTNPNLjx',
        chain: ChainType.BTC,
      },
    ],
  },
  {
    isPrivate: true,
    reportId: '3',
    category: ScamCategory.RUG_PULL,
    description: MEDIUM_DESCRIPTION,
    authorUsername: 'crytopunk',
    reportDate: new Date('1/4/22'),
    netVoteCount: 2,
    source: ReportSource.CHAINABUSE,
    addresses: [
      {
        id: '1',
        address: 'AUrox7sHx1L8mxEPrNqkVjHa16CXQ73UbgZtZTNPNLjx',
        chain: ChainType.BTC,
      },
      {
        id: '2',
        address: 'AUrox7sHx1L8mxEPrNqkVjHa16CXQ73UbgZtZTNPNLjx',
        chain: ChainType.BTC,
      },
    ],
  },
  {
    isPrivate: false,
    reportId: '4',
    category: ScamCategory.RUG_PULL,
    description: SHORT_DESCRIPTION,
    authorUsername: 'crytopunk',
    reportDate: new Date('1/1/22'),
    netVoteCount: 24,
    source: ReportSource.CHAINABUSE,
    addresses: [
      {
        id: '1',
        address: 'AUrox7sHx1L8mxEPrNqkVjHa16CXQ73UbgZtZTNPNLjx',
        chain: ChainType.BTC,
      },
    ],
  },
  {
    isPrivate: false,
    reportId: '5',
    category: ScamCategory.RUG_PULL,
    description: SHORT_DESCRIPTION,
    authorUsername: 'crytopunk',
    reportDate: new Date('1/10/22'),
    netVoteCount: 1,
    source: ReportSource.CHAINABUSE,
    addresses: [
      {
        id: '1',
        address: 'AUrox7sHx1L8mxEPrNqkVjHa16CXQ73UbgZtZTNPNLjx',
        chain: ChainType.BTC,
      },
    ],
  },
];

export const PRIVATE_REPORT_DUMMY_DETAILS = {
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pellentesque vulputate iaculis. Integer lacus sapien, auctor quis nisi non, pulvinar accumsan ligula. Vivamus quis odio ut est iaculis lobortis ut eu leo. Nam mattis varius gravida. Vivamus aliquet vestibulum ex et rutrum. Mauris eu tempor mi, ac porttitor magna. Etiam id fermentum orci, et malesuada lacus. Morbi facilisis malesuada hendrerit. Fusce eu magna auctor, fringilla libero eget, sodales elit.',
  lexicalSerializedDescription: undefined,
  addresses: [
    {
      address: 'address-1',
      chain: ChainType.ETH,
    },
    {
      address: 'address-2',
      chain: ChainType.ETH,
    },
    {
      address: 'address-3',
      chain: ChainType.ETH,
    },
    {
      address: 'address-4',
      chain: ChainType.ETH,
    },
  ],
};

export const reportsForTable = [
  {
    createdAt: '2022-04-19T08:16:56.807Z',
    scamCategory: ScamCategory.CONTRACT_EXPLOIT,
    description:
      '"SOLANA (SOL) Giveaway" is the name of a scam promoted on various deceptive websites. The scheme offers to double SOL cryptocurrency contributions that users make to the fake program. Instead, users simply lose all that they transfer to this scam.',
    reportedBy: {
      id: '300e5297-4148-4f00-bb9a-6a1afdd6a3bf',
      username: 'john_doe',
    },
    addresses: [
      {
        id: 'b00cbd39-d206-4ed9-8ad2-0ba491f522d7',
        address: '0x0000000000000000000001',
        chain: ChainType.BTC,
      },
      {
        id: 'b00cbd39-d206-4ed9-8ad2-0ba491f522d7',
        address: 'ABCXRAhgxEwP5Gpz51ZYsL8XoEh16vsUfKzi5Kd8SBF8rasdf',
        chain: ChainType.SOL,
      },
      {
        id: 'b00cbd39-d206-4ed9-8ad2-0ba491f522d7',
        address: 'DXRAhgxEwP5Gpz51ZYsL8XoEh16vsUfKzi5Kd8SBF8rP',
        chain: ChainType.SOL,
      },
      {
        id: 'b00cbd39-d206-4ed9-8ad2-0ba491f522d7',
        address: 'DXRAhgxEwP5Gpz51ZYsL8XoEh16vsUfKzi5Kd8SBF8rP',
        chain: ChainType.ETH,
      },
    ],
  },
  {
    createdAt: '2022-04-19T08:16:56.627Z',
    scamCategory: ScamCategory.AIRDROP,
    description: 'he took all my money',
    domain: 'https://officialsoldrop.com/',
    reportedBy: {
      id: '300e5297-4148-4f00-bb9a-6a1afdd6a3bf',
      username: 'john_doe',
    },
    addresses: [
      {
        id: '95a6bef9-8b85-4fe6-b9d0-86ad97d3078e',
        domain: 'https://officialsoldrop.com/',
      },
    ],
  },
  {
    createdAt: '2022-04-19T08:16:56.627Z',
    scamCategory: ScamCategory.OTHER,
    categoryDescription: 'lorem ipsum dolar sit',
    description: '',
    domain: 'https://officialsoldrop.com/',
    reportedBy: {
      id: '300e5297-4148-4f00-bb9a-6a1afdd6a3bf',
      username: 'john_doe',
    },
    addresses: [
      {
        id: '95a6bef9-8b85-4fe6-b9d0-86ad97d3078e',
        domain: 'https://scammer.com/',
      },
      {
        id: '95a6bef9-8b85-4fe6-b9d0-86ad97d3078e',
        address: '0xb11234123412341234',
        chain: ChainType.BINANCE,
      },
    ],
  },
];
